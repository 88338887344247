import React from 'react'
import PropTypes from 'prop-types'

import './carousel.scss'

const isEqual = require('react-fast-compare')

export class CustomCarousel extends React.Component {
  state = {
    slideTotal: 0,
    slideCurrent: -1,
    slides: [],
    height: '0px',
    passwordEntered: false
  }

  interval = null

  handleSubmit = (event) => {
    event.preventDefault()
    let passwordEntered = event.target.children[0].children[0].value
    if (passwordEntered === '$vxWzS(3CxJx6xdd5s'){
      sessionStorage.setItem('passwordEntered', 'true')
      this.setState({passwordEntered: !this.state.passwordEntered})
    } else {
      alert('Incorrect Password')
    }

  }

  componentDidMount () {
    let slides = []
    this.props.slides.forEach(slide => {
      let slideobject = {
        class: 'slider-single proactivede',
        element: slide
      }
      slides.push(slideobject)
    })
    this.setState((prevState, props) => {
      return {
        slides,
        slideTotal: this.props.slides.length - 1
      }
    })
    if (this.state.slideCurrent === -1)
      setTimeout(() => {
        this.slideRight()
        if (this.props.autoplay)
          this.interval = setTimeout(() => {
            this.slideRight()
          }, this.props.interval)
      }, 500)
  }
  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.slides && prevProps.slides[0] && this.props.slides[0]) {
      if (!isEqual(prevProps.slides[0], this.props.slides[0])) {
        let slides = []
        this.props.slides.forEach(slide => {
          let slideobject = {
            class: 'slider-single proactivede',
            element: slide
          }
          slides.push(slideobject)
        })
        this.setState((prevState, props) => {
          return {
            slides,
            slideTotal: this.props.slides.length - 1,
            slideCurrent: -1
          }
        })
        this.setState((prevState, props) => {
          return { ...prevState }
        })
        setTimeout(() => {
          this.slideRight()
          if (this.props.autoplay) {
            clearTimeout(this.interval)
            this.interval = setTimeout(() => {
              this.slideRight()
            }, this.props.interval)
          }
        }, 500)
      }
    }
  }

  slideRight () {
    let { slideCurrent, slideTotal } = this.state
    let preactiveSlide, proactiveSlide
    const activeClass = 'slider-single active'
    let slide = this.state.slides
    if (slideTotal > 1) {
      if (slideCurrent < slideTotal) {
        slideCurrent++
      } else {
        slideCurrent = 0
      }
      if (slideCurrent > 0) {
        preactiveSlide = slide[slideCurrent - 1]
      } else {
        preactiveSlide = slide[slideTotal]
      }
      let activeSlide = slide[slideCurrent]
      if (slideCurrent < slideTotal) {
        proactiveSlide = slide[slideCurrent + 1]
      } else {
        proactiveSlide = slide[0]
      }

      slide.forEach((slid, index) => {
        if (slid.class.includes('preactivede')) {
          slid.class = 'slider-single proactivede'
        }
        if (slid.class.includes('preactive')) {
          slid.class = 'slider-single preactivede'
        }
      })

      preactiveSlide.class = 'slider-single preactive'
      activeSlide.class = activeClass
      proactiveSlide.class = 'slider-single proactive'
      this.setState((prevState, props) => {
        return { slides: slide, slideCurrent }
      })
      // if (document.getElementsByClassName('slider-single active').length > 0) {
      //   setTimeout(() => {
      //     let height = document.getElementsByClassName(
      //       'slider-single active'
      //     )[0].clientHeight
      //     this.setState((prevState, props) => {
      //       return { height: height + 'px' }
      //     })
      //   }, 500)
      // }
      if (this.props.autoplay) {
        clearTimeout(this.interval)
        this.interval = setTimeout(() => {
          this.slideRight()
        }, this.props.interval)
      }
    } else {
      if (slide[0].class !== activeClass) {
        slide[0].class = activeClass
        this.setState((prevState, props) => {
          return { slides: slide, slideCurrent: 0 }
        })
      }
    }
  }
  slideLeft () {
    let { slideCurrent, slideTotal } = this.state
    if (slideTotal > 1) {
      let preactiveSlide, proactiveSlide
      let slide = this.state.slides
      if (slideCurrent > 0) {
        slideCurrent--
      } else {
        slideCurrent = slideTotal
      }

      if (slideCurrent < slideTotal) {
        proactiveSlide = slide[slideCurrent + 1]
      } else {
        proactiveSlide = slide[0]
      }
      var activeSlide = slide[slideCurrent]
      if (slideCurrent > 0) {
        preactiveSlide = slide[slideCurrent - 1]
      } else {
        preactiveSlide = slide[slideTotal]
      }
      slide.forEach((slid, index) => {
        if (slid.class.includes('proactivede')) {
          slid.class = 'slider-single preactivede'
        }
        if (slid.class.includes('proactive')) {
          slid.class = 'slider-single proactivede'
        }
      })
      preactiveSlide.class = 'slider-single preactive'
      activeSlide.class = 'slider-single active'
      proactiveSlide.class = 'slider-single proactive'
      this.setState((prevState, props) => {
        return { slides: slide, slideCurrent }
      })
      // if (document.getElementsByClassName('slider-single active').length > 0) {
      //   setTimeout(() => {
      //     let height = document.getElementsByClassName(
      //       'slider-single active'
      //     )[0].clientHeight
      //     this.setState((prevState, props) => {
      //       return { height: height + 'px' }
      //     })
      //   }, 500)
      // }
    }
  }
  render () {
    const {handleSubmit} = this
    return (
      <div className='react-3d-carousel' style={{ height: this.state.height }}>
        <div className='col-12 col-md-8'>
          <h2 className='section-title m-0' style={{ display: 'inline-block' }}>
            Projects
          </h2>
          <div
            style={{
              display: 'inline-block',
              height: '1px',
              width: '300px',
              backgroundColor: 'rgb(48, 60, 85)',
              position: 'relative',
              top: '-7px',
              marginLeft: '20px'
            }}
          ></div>
        </div>
        {this.state.slides && this.state.slides.length > 0 && (
          <div className='slider-container'>
            <div className='slider-content'>
              {this.state.slides.map((slider, index) => {
                return (
                  <div className={slider.class} key={index}>
                    <button
                      className='slider-left'
                      onClick={this.slideLeft.bind(this)}
                    >
                      <svg width='24' height='24' viewBox='0 0 24 24'>
                        <path d='M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z'></path>
                      </svg>
                    </button>
                    <button
                      className='slider-right'
                      onClick={this.slideRight.bind(this)}
                    >
                      <svg width='24' height='24' viewBox='0 0 24 24'>
                        <path d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'></path>
                      </svg>
                    </button>

                    <div className='slider-single-content col-10 p-0'>
                      {this.props.project[index].passwordRequired && !sessionStorage.getItem('passwordEntered') ? (
                        <>
                          <h1 className='project-headline'>
                            Please enter Password to view this Project
                          </h1>
                          <img src={require('../images/locked-project-desktop.png')} alt=""/>
                          {/* {slider.element image */}
                          <div className='project-description'>
                            <form onSubmit={handleSubmit} className='main-form'>
                              <label htmlFor='password'>
                                Password:
                                <input id='password' type="text" className='form-control'/>
                              </label>
                              <input className="form-submit-btn btn btn-dark" type='submit' value='Submit'/>
                            </form>
                            {/* {this.props.project[index].description} */}
                            You are not authorised to view this project.
                          </div>
                        </>
                      ) : (
                        <>
                          <h1 className='project-headline'>
                            {this.props.project[index].title}
                          </h1>
                          {slider.element}
                          <div className='project-description'>
                            <a href={this.props.project[index].website}>
                              <p>View Project</p>
                            </a>
                            {this.props.project[index].description}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
    )
  }
}
CustomCarousel.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.element),
  autoplay: PropTypes.bool,
  interval: PropTypes.number
}
CustomCarousel.defaultProps = {
  autoplay: false,
  interval: 3000
}
