import React, {useState} from 'react'
import { Slide } from 'react-slideshow-image'
import './mobileSlideShow.css'
import 'react-slideshow-image/dist/styles.css'

const MobileSlideshow = props => {
  const { mobileSlides } = props

  const [password, setPassword] = useState(false)


  const handleSubmit = (event) => {
    event.preventDefault()
    let passwordEntered = event.target.children[0].children[0].value
    if (passwordEntered === '$vxWzS(3CxJx6xdd5s'){
      sessionStorage.setItem('passwordEntered', 'true')
      setPassword(!password)
    } else {
      alert('Incorrect Password')
    }

  }
  return (
    <Slide>
      {mobileSlides.map((slide, index) =>
        slide.passwordRequired && !sessionStorage.getItem('passwordEntered') ? (
          <div key={index + 1} className='each-slide'>
            <img src={require('../images/locked-project-mobile.png')} alt='' />
            <p className='mobile-project-link'>Project Locked</p>
            <form onSubmit={handleSubmit} className='mobile-form'>
              <label htmlFor='password'>
                Password:
                <input id='password' type="text" className='form-control'/>
              </label>
              <input className="form-submit-btn btn btn-dark" type='submit' value='Submit'/>
            </form>
            <p className='mobile-project-description'>
              You are not authorised to view this project.
            </p>
          </div>
        ) : (
          <div key={index + 1} className='each-slide'>
            <img src={slide.mobileImage} alt='' />
            <a href={slide.website}>
              <p className='mobile-project-link'>View Project</p>
            </a>
            <p className='mobile-project-description'>{slide.description}</p>
            <p className='mobile-project-link'>Project Locked</p>
            <form onSubmit={handleSubmit} className='mobile-form'>
              <label htmlFor='password'>
                Password:
                <input id='password' type="text" className='form-control'/>
              </label>
              <input className="form-submit-btn btn btn-dark" type='submit' value='Submit'/>
            </form>
          </div>
        )
      )}
    </Slide>
  )
}

export default MobileSlideshow
