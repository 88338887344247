import React, { useState, useEffect } from 'react'

import { CustomCarousel } from './customcarousel'
import MobileSlideShow from './MobileSlideShow'
import './portfolio.css'

const ProjectCarousel = props => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const { allProjects } = props

  let slides = allProjects.map(project => <img src={project.image} alt='' />)
  // let mobileSlides = allProjects.map(project => project.mobileImage)

  return (
    <div className='project-details col-12 p-0'>
      {windowSize.width > 768 ? (
        <CustomCarousel
          slides={slides}
          autoplay={false}
          interval={1000}
          project={allProjects}
        />
      ) : (
        <MobileSlideShow autoplay={false} mobileSlides={allProjects} />
      )}
    </div>
  )
}

export default ProjectCarousel
