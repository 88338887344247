import React, { useState, useEffect } from 'react'

import ProjectCarousel from '../components/projectcarousel'

import portfolioWork from '../static.json'
import '../components/portfolio.css'

const Portfolio = () => {
  const [portfolio, setPortfolio] = useState([])

  useEffect(() => {
    setPortfolio(portfolioWork)
  }, [])

  return (
    <div id='projects' className='projects row'>
      <>
        {portfolio.length > 0 ? (
          <ProjectCarousel allProjects={portfolio} />
        ) : (
          'loading...'
        )}
      </>
    </div>
  )
}

export default Portfolio
